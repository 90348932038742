body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modelTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.modelTable th,
.modelTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  max-width: 100px;
  overflow-x: auto;
  overflow-y: auto;
}

.modelTable th {
  background-color: #04AA6D;
  color: white;
}

.modelTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.modelTable tr:hover {
  background-color: #ddd;
}

.editInput {
  width: 100%;
  padding: 6px;
  margin: 4px 0;
}

.editButton, .saveButton {
  cursor: pointer;
  padding: 8px 16px;
  margin: 5px;
  border: none;
  color: white;
}

.editButton {
  background-color: #FFA500;
}

.saveButton {
  background-color: #4CAF50;
}

.scrollable-checkbox-list {
  height: 200px; /* Adjust height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 1px solid #ccc; /* Optional: adds a border around the scrollable area */
}

/* Add this to your CSS file */
.scrollable-container {
  max-height: 75px; /* or any appropriate value */
  overflow-y: auto; /* enable vertical scroll */
  width: 100%; /* ensure it fills the cell */
}

.error-metric {
  background-color: rgba(250, 128, 114, 0.3)
}
